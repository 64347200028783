<script setup lang="ts">
const { user, reloadUser } = useUser()

await reloadUser()

const localePath = useLocalePath()

if (!user.value) {
  await navigateTo(localePath('/auth/login'))
  throw new Error('User not found')
}

if (!user.value.onboardingComplete) {
  await navigateTo(localePath('/onboarding'))
  throw new Error('Onboarding not complete')
}
</script>

<template>
  <main class="h-screen w-screen">
    <slot />
  </main>
</template>
